<script setup>
    import { CorporationBilling, UserRoles } from '~/enum';

    const { $me } = useNuxtApp();

    const localePath = useLocalePath();

    const viewport = useViewport();
    const menuHovered = ref(false);

    const menuMaximisedForced = ref(true);

    const menuMaximised = computed(() => {
        return menuMaximisedForced.value || menuHovered.value;
    });

    const userRole = $me.get().role;

    // Props definition
    const props = defineProps({
        menuOpen: {
            type: Boolean,
            default: true,
        },
        pageUrl: {
            type: String,
            default: '',
        },
    });

    const emit = defineEmits(['click']);

    // Menu
    const menu = ref([
        {
            // Pré-ventes
            label: 'Pre-sales',
            icon: 'material-symbols:speed-outline',
            role: [UserRoles.ADMIN, ...UserRoles.operatorsBuyer()],
            items: [
                {
                    label: 'New registrants',
                    to: `${backendUrl()}/admin/dashboard/buyers/new_registrants`,
                },
                {
                    label: 'Offers to be activated',
                    to: `${backendUrl()}/admin/dashboard/offers/to_activate`,
                },
                {
                    label: 'Bids to validate',
                    to: `${backendUrl()}/admin/dashboard/offers/bids_to_validate`,
                },
                {
                    label: 'Offers to be validated',
                    to: `${backendUrl()}/admin/dashboard/offers/to_validate`,
                },
            ],
        },
        {
            // Dashboard vendeur
            label: 'Dashboard',
            icon: 'cil:speedometer',
            role: UserRoles.sellers(),
            items: [
                {
                    label: 'Your insights',
                    to: `${backendUrl()}/sellers/dashboard`,
                },
                {
                    label: 'Your payouts',
                    to: `${backendUrl()}/sellers/dashboard/payouts`,
                },
                {
                    label: 'Accounting reconciliation',
                    to: `${backendUrl()}/sellers/dashboard/payouts/advanced`,
                },
            ],
        },
        {
            // Liste des acheteurs
            label: 'Buyers',
            icon: 'material-symbols:globe',
            role: [UserRoles.ADMIN, UserRoles.ACCOUNTANT, ...UserRoles.operatorsBuyer()],
            items: [
                {
                    label: 'Buyers',
                    to: `${backendUrl()}/admin/buyers`,
                },
                {
                    label: 'Tags',
                    role: [UserRoles.ADMIN],
                    to: `${backendUrl()}/admin/corporation_tags`,
                },
                {
                    label: 'Payment and shipping tracking',
                    role: [UserRoles.ADMIN, ...UserRoles.operatorsBuyer()],
                    to: `${backendUrl()}/admin/dashboard/orders/transports`,
                },
                {
                    label: 'SAV',
                    to: localePath('litigations'),
                },
            ],
        },
        {
            // Liste des vendeurs (admins)
            label: 'Sellers',
            icon: 'material-symbols:group',
            role: [UserRoles.ADMIN, UserRoles.ACCOUNTANT],
            items: [
                {
                    label: 'Sellers',
                    to: `${backendUrl()}/admin/sellers`,
                },
                {
                    label: 'Commercials',
                    role: [UserRoles.ADMIN],
                    to: `${backendUrl()}/admin/commercials`,
                },
                {
                    label: 'Production agents',
                    role: [UserRoles.ADMIN],
                    to: `${backendUrl()}/admin/production_agents`,
                },
                {
                    label: 'Insights',
                    role: [UserRoles.ADMIN],
                    to: `${backendUrl()}/admin/dashboard/insights`,
                },
            ],
        },
        {
            // Offres
            label: 'Offers',
            icon: 'material-symbols:business-center-rounded',
            role: [...UserRoles.sellers(), ...UserRoles.managers()],
            items: [
                {
                    label: 'List',
                    role: UserRoles.sellers(),
                    to: `${backendUrl()}/sellers/offers`,
                },
                {
                    label: 'List',
                    role: UserRoles.managers(),
                    to: localePath('offers'),
                },
                {
                    label: 'Products imports',
                    to: localePath('products-import'),
                },
                {
                    label: 'Offers composition',
                    to: localePath('offers-composition'),
                },
                {
                    label: 'Products import headings',
                    role: [UserRoles.ADMIN],
                    to: `${backendUrl()}/admin/product_import_headings`,
                },
                {
                    label: 'Remaining products',
                    to: `${backendUrl()}/sellers/products/remaining`,
                },
            ],
        },
        {
            // Camions (logisticiens)
            label: 'Receptions - Trucks',
            icon: 'ri:truck-line',
            role: UserRoles.logisticians(),
            to: localePath('trucks'),
        },
        {
            // Commandes (logisticiens)
            label: 'Shipping - Orders',
            icon: 'material-symbols:shopping-cart-outline',
            role: UserRoles.logisticians(),
            to: localePath('orders'),
        },
        {
            // Commandes (vendeurs)
            label: 'Orders',
            icon: 'material-symbols:shopping-cart-outline',
            role: UserRoles.sellers(),
            to: `${backendUrl()}/sellers/orders`,
        },
        {
            // Commandes (admins)
            label: 'Orders',
            icon: 'material-symbols:shopping-cart-outline',
            role: [UserRoles.ADMIN, UserRoles.ACCOUNTANT, ...UserRoles.operatorsBuyer()],
            items: [
                {
                    label: 'List',
                    to: localePath('orders'),
                },
                {
                    label: 'Orders tracking',
                    to: localePath('orders-tracking-id'),
                },
            ],
        },
        {
            // Facturation (vendeurs)
            label: 'Invoicing',
            icon: 'material-symbols:docs',
            role: UserRoles.sellers(),
            items: [
                {
                    label: 'Invoices / Credit notes',
                    billing: CorporationBilling.commissionaires(),
                    to: localePath('invoices-seller-billing'),
                },
                {
                    label: 'Invoices',
                    billing: CorporationBilling.mandataires(),
                    to: localePath('invoices-seller-billing'),
                },
                {
                    label: 'Credit notes',
                    billing: CorporationBilling.mandataires(),
                    to: localePath('invoices-seller-credit'),
                },
                {
                    label: 'Stocklear invoices',
                    to: localePath('invoices-seller-stocklear'),
                },
            ],
        },
        {
            // Camions (vendeurs)
            label: 'Trucks',
            icon: 'ri:truck-line',
            role: UserRoles.sellers(),
            to: `${backendUrl()}/sellers/trucks`,
        },
        {
            // Liste des acheteurs (vendeurs)
            label: 'Buyers',
            icon: 'material-symbols:group',
            role: UserRoles.sellers(),
            to: `${backendUrl()}/sellers/buyers`,
        },
        {
            // Entrepots
            label: 'Warehouses',
            icon: 'material-symbols:store',
            role: UserRoles.sellers(),
            to: `${backendUrl()}/sellers/warehouses`,
        },
        {
            // Messages (vendeurs)
            label: 'Messages',
            icon: 'material-symbols:android-messages',
            role: UserRoles.sellers(),
            to: localePath('messages-seller'),
        },
        {
            // Voir les lots en ligne (vendeurs)
            label: 'Lots online',
            icon: 'material-symbols:shopping-cart',
            role: UserRoles.sellers(),
            to: `/`,
        },
        {
            // Mon entreprise (vendeurs)
            label: 'My corporation',
            icon: 'material-symbols:business-center-rounded',
            role: UserRoles.sellers(),
            to: `${backendUrl()}/profile/corporation`,
        },
        {
            // Transports
            label: 'Transports',
            icon: 'ri:truck-line',
            role: [UserRoles.ADMIN, ...UserRoles.operatorsBuyer(), ...UserRoles.transporters()],
            to: localePath('transports'),
        },
        {
            // Fulfillment
            label: 'Fulfillment',
            icon: 'material-symbols:bookmark',
            role: [UserRoles.ADMIN, ...UserRoles.operatorsBuyer()],
            items: [
                {
                    label: 'Dashboard',
                    to: `${backendUrl()}/admin/dashboard/pallets_stock`,
                },
                {
                    label: 'Trucks',
                    to: localePath('trucks'),
                },
                {
                    label: 'Warehouses',
                    to: `${backendUrl()}/admin/warehouses`,
                },
            ],
        },
        {
            // Facturation (admins)
            label: 'Invoicing',
            icon: 'material-symbols:docs',
            role: [UserRoles.ADMIN, UserRoles.ACCOUNTANT, UserRoles.operatorsBuyer()],
            items: [
                {
                    label: 'Invoices',
                    to: `${backendUrl()}/admin/invoices`,
                },
                {
                    label: 'Credit notes',
                    to: `${backendUrl()}/admin/invoices_credit`,
                },
                {
                    label: 'Pro-forma',
                    to: `${backendUrl()}/admin/invoices_proforma`,
                },
                {
                    label: 'Invoices to validate',
                    to: `${backendUrl()}/admin/invoices_to_validate`,
                },
                {
                    label: 'Seller payout',
                    to: `${backendUrl()}/admin/dashboard/orders/payouts`,
                },
            ],
        },
        {
            // Comptabilité
            label: 'Accounting',
            permission: 'stripe reports',
            icon: 'material-symbols:speed-outline',
            items: [
                {
                    label: 'Stripe reports',
                    to: `${backendUrl()}/admin/stripe-reports`,
                },
                {
                    label: 'Unpaid invoices',
                    to: `${backendUrl()}/admin/accounting_reports/unpaid_invoices`,
                },
                {
                    label: 'Logistics',
                    role: [UserRoles.ADMIN, UserRoles.ACCOUNTANT],
                    to: `${backendUrl()}/admin/logistics`,
                },
            ],
        },
        {
            // Listes d'utilisateurs
            label: 'Users',
            icon: 'material-symbols:group',
            role: [UserRoles.ADMIN, UserRoles.OPERATOR_SAV_TRANSPORT],
            items: [
                {
                    label: 'Suppliers',
                    to: localePath('suppliers'),
                },
                {
                    label: 'Administrators',
                    role: [UserRoles.ADMIN],
                    to: `${backendUrl()}/admin/users`,
                },
            ],
        },
        {
            // Pays
            label: 'Countries',
            icon: 'material-symbols:public',
            role: [UserRoles.ADMIN],
            to: localePath('countries'),
        },
        {
            // Frais
            label: 'Fees',
            icon: 'material-symbols:euro-symbol',
            role: [UserRoles.ADMIN],
            to: localePath('fees'),
        },
        {
            // Messages rapides
            label: 'Messages',
            icon: 'material-symbols:android-messages',
            role: [UserRoles.ADMIN, ...UserRoles.operatorsBuyer()],
            to: localePath('messages'),
        },
        {
            // Paramètres
            label: 'Settings',
            icon: 'material-symbols:settings',
            role: [UserRoles.ADMIN],
            to: `${backendUrl()}/admin/settings/`,
        },
        {
            // Types de produits
            label: 'Product types',
            icon: 'material-symbols:category',
            role: [UserRoles.ADMIN],
            to: `${backendUrl()}/admin/product_types/`,
        },
        {
            // Catégories
            label: 'Categories',
            icon: 'material-symbols:category',
            role: [UserRoles.ADMIN],
            to: `${backendUrl()}/admin/categories/`,
        },
        {
            // Mon profil
            label: 'My profile',
            icon: 'material-symbols:person',
            to: `${backendUrl()}/profile/me`,
        },
        {
            // Marketing
            label: 'Marketing',
            icon: 'material-symbols:bar-chart-4-bars',
            role: [UserRoles.ADMIN],
            items: [
                {
                    label: 'Banners',
                    to: `${backendUrl()}/admin/banners`,
                },
                {
                    label: 'Promo cards',
                    to: localePath('promo-cards'),
                },
                {
                    label: 'Alert messages',
                    to: `${backendUrl()}/admin/alert_messages`,
                },
                {
                    label: 'Faqs',
                    to: `${backendUrl()}/admin/faqs`,
                },
                {
                    label: 'Reviews',
                    to: `${backendUrl()}/admin/reviews`,
                },
                {
                    label: 'Pages',
                    to: `${backendUrl()}/admin/pages`,
                },
                {
                    label: 'Newsletters',
                    to: localePath('marketing-newsletter'),
                },
                {
                    label: 'Brands',
                    to: `${backendUrl()}/admin/brands`,
                },
                {
                    label: 'Segments',
                    to: localePath('marketing-segments'),
                },
            ],
        },
    ]);

    const filteredMenu = computed(() => {
        return menu.value.filter((item) => {
            // N'affiche pas le menu si l'utilisateur n'a pas le rôle
            if (item.role && !item.role.includes(userRole)) {
                return false;
            }

            // N'affiche pas le menu si l'utilisateur n'a pas la permission
            if (item.permission && !$me.can(item.permission)) {
                return false;
            }

            if (item.items?.length) {
                item.items = item.items.filter((subItem) => {
                    // N'affiche pas le sous-menu si l'utilisateur n'a pas le rôle
                    if (subItem.role && !subItem.role.includes(userRole)) {
                        return false;
                    }

                    // N'affiche pas le sous-menu si l'utilisateur n'a pas la permission
                    if (subItem.permission && !$me.can(subItem.permission)) {
                        return false;
                    }

                    // N'affiche pas le sous-menu si le type de facturation ne correspond pas
                    if (
                        subItem.billing &&
                        !subItem.billing.includes($me.get().corporation?.billing)
                    ) {
                        return false;
                    }

                    return true;
                });
            }

            return true;
        });
    });
</script>

<template>
    <div
        id="app__back__menu"
        :class="{ maximized: menuMaximised, minimized: !menuMaximised, menu__open: menuOpen }"
        @mouseenter="menuHovered = true"
        @mouseleave="menuHovered = false"
    >
        <div id="app__back__menu__container">
            <div id="app__back__menu__header">
                <slot name="header">
                    <LayoutLogo :white="true" :reduced="!menuMaximised" />

                    <div
                        v-if="viewport.isGreaterThan('md')"
                        @click="menuMaximisedForced = !menuMaximisedForced"
                    >
                        <Icon
                            v-if="menuMaximisedForced"
                            name="mdi:backburger"
                            class="app__back__menu__header__icon"
                        />
                        <Icon
                            v-else-if="menuMaximised"
                            name="mdi:forwardburger"
                            class="app__back__menu__header__icon"
                        />
                    </div>

                    <div v-else>
                        <Icon
                            name="mdi:window-close"
                            class="app__back__menu__header__icon"
                            @click="emit('click')"
                        />
                    </div>
                </slot>
            </div>

            <LayoutBackendPanelMenu
                :menus="filteredMenu"
                :menuMaximised="menuMaximised"
                :pageUrl="props.pageUrl"
            />
        </div>
    </div>
</template>
