<script setup>
    // Props definition
    const props = defineProps({
        menus: {
            type: Array,
            required: true,
        },
        menuMaximised: {
            type: Boolean,
            default: true,
        },
        pageUrl: {
            type: String,
            default: '',
        },
    });

    const router = useRoute();

    /**
     * Will parse all menues and check if contains a submenus with url equal to current url
     * to know if submenu should be open or not
     * @param {boolean} menuMaximised
     */
    const checkIfSubMenuOpen = (menuMaximised) => {
        for(const item of props.menus) {
            item.showSubMenu = false;

            //If tab menu closed no need to check for submenus
            if (!menuMaximised) {
                continue;
            }

            //If has no submenus no need to check for submenus
            if (!item.items) {
                continue;
            }

            //Check if submenu contains current url
            for (const subItem of item.items) {
                if (subItem.to === (props.pageUrl || router.path)) {
                    item.showSubMenu = true;
                }
            }
        }
    }

    checkIfSubMenuOpen(props.menuMaximised)

    //If menu is opened need to open the correct group
    watch(
        () => props.menuMaximised,
        (newValue) => {
            checkIfSubMenuOpen(newValue);
        }
    );
</script>

<template>
    <div id="panel__menu__container">
        <div v-for="item in props.menus" class="panel__menu__items">
            <NuxtLink
                :to="item.to"
                class="panel__menu__item"
                :class="{
                    'current__url': item.to === (props.pageUrl || router.path),
                    'sub__menu__open': item.showSubMenu
                }"
                @click="item.showSubMenu = !item.showSubMenu"
            >
                <div class="panel__menu__item__icon">
                    <Icon :name="item.icon" />
                </div>

                <div v-if="props.menuMaximised" class="panel__menu__item__label">
                    {{ $t(item.label) }}
                </div>

                <div v-if="item.items" class="ml-auto">
                    <Icon :name="`material-symbols:keyboard-arrow-${item.showSubMenu ? 'up' : 'down'}`" />
                </div>
            </NuxtLink>

            <Transition name="backend__sub__menu">
                <div v-if="item.items && item.showSubMenu" class="panel__menu__sub__menu">
                    <NuxtLink
                        v-for="subItem in item.items"
                        :to="subItem.to"
                        class="panel__menu__item panel__menu__sub__item"
                        :class="{'current__url': subItem.to === (props.pageUrl || router.path)}"
                    >
                        <div v-if="subItem.icon" class="panel__menu__sub__item__icon">
                            <Icon :name="subItem.icon" />
                        </div>

                        <div class="panel__menu__sub__item__label">
                            {{ $t(subItem.label) }}
                        </div>
                    </NuxtLink>
                </div>
            </Transition>
        </div>
    </div>
</template>