<script setup>
    const viewport = useViewport();

    const { $me, $api } = useNuxtApp();
    const me = $me.get();

    // Initialize i18n
    const { t: $t } = useI18n();

    const contextMenu = ref();

    const emit = defineEmits([
        'click'
    ]);

    const stringToColour = () => {
        let str = compactName();
        let hash = 0;

        str.split('').forEach(char => {
            hash = char.charCodeAt(0) + ((hash << 5) - hash)
        })

        let colour = '#'
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff
            colour += value.toString(16).padStart(2, '0')
        }

        let style = `background-color: ${colour}`;

        if (isColorDark(colour)) {
            style += '; color: white'
        }

        return style;
    }

    const isColorDark = (color) => {
        color = color.substring(1);
        let rgb = parseInt(color, 16);
        let r = (rgb >> 16) & 0xff;
        let g = (rgb >>  8) & 0xff;
        let b = (rgb >>  0) & 0xff;

        let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        return luma < 40;
    }

    const compactName = () => {
        return `${me.first_name[0].toUpperCase()}${me.name[0].toUpperCase()}`
    }

    /**
     * Show the context menu
     */
    const showContextMenu = (event) => {
        try {
            contextMenu.value.toggle(event);
        } catch(e) {
            console.log(e, contextMenu);
        }
    }

    // Logout from the app
    const logout = () => {
        $api.post('logout', {
            onSuccess: () => {
                window.location.reload();
            }
        });
    };

    const contextMenuItems = ref([ 
        {
            label: $t('Profile'),
            icon: 'material-symbols:person',
            route: `${backendUrl()}/profile/me`,
        },
        {
            label: $t('Logout'),
            icon: 'material-symbols:lock',
            action: logout,
        }
    ])
</script>

<template>
    <header id="app__header">
        <!-- Main header -->
        <nav id="app__header__main">
            <!-- Menubar -->
            <PrimevueMenubar>
                <!-- MenuBar at start when mobile -->
                <template #start v-if="viewport.isLessThan('md')">
                    <div id="app__header__main__menu__burger" @click="emit('click')">
                        <Icon name="iconamoon:menu-burger-horizontal-bold"/>
                    </div>
                </template>

                <!-- End of the bar -->
                <template #end>
                    <div id="app__header__main__end">
                        <!-- Notifications Novu -->
                        <div v-if="$me.check() && $me.get().novu_id">
                            <LazyNovuNotificationCenter />
                        </div>

                        <div id="app__header__main__end__user" @click="showContextMenu" aria-haspopup="true" aria-controls="user__context__menu">
                            <div class="app__header__main__end__avatar" :style="stringToColour()">
                                <span>{{ compactName() }}</span>
                            </div>

                            <span class="app__header__main__end__name">{{ me.first_name }} {{ me.name }}</span>
                        </div>

                        <LayoutBackendDropdownMenu id="user__context__menu" ref="contextMenu" :items="contextMenuItems"/>
                    </div>
                </template>
            </PrimevueMenubar>
        </nav>
    </header>
</template>

<style lang="scss">
    #app__header__main__end {
        cursor: pointer;
    }

    #user__context__menu {
        width: initial;
        background-color: white;
        border-radius: 0.5rem;
        overflow: hidden;

        .p-menuitem {
            padding: 0.75rem 1.25rem;
            background-color: white;
            cursor: pointer;

            &:hover {
                background-color: #f3f4f6;

                .p-menuitem-content {
                    background-color: #f3f4f6;
                }
            }

            .p-menuitem-content {
                background-color: white;
            }
        }
    }
</style>