<script setup>
    import Menu from 'primevue/menu';

    const props = defineProps({
        items: {
            type: Array,
            required: true,
        },
    });

    const menu = ref();

    const toggle = (event) => {
        try {
            menu.value.toggle(event);
        } catch(e) {
            console.log(e, menu);
        }
    }

    defineExpose({
        toggle,
    });
</script>

<template>
    <Menu ref="menu" :model="props.items" :popup="true">
        <template #item="{ label, item }">
            <NuxtLink v-if="item.route" :to="item.route">
                <Icon :name="item.icon"/>
                <span>{{ label }}</span>
            </NuxtLink>

            <a v-else-if="item.url" :href="item.url">
                <Icon :name="item.icon"/>
                <span>{{ label }}</span>
            </a>

            <span v-else @click="item.action">
                <Icon :name="item.icon"/>
                <span>{{ label }}</span>
            </span>
        </template>
    </Menu>
</template>