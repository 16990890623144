<script setup>
    import PrimevueToast from 'primevue/toast';

    const menuOpen = ref(false);

    // Add data layout attribute to body
    useHead({
        bodyAttrs: {
            'data-layout': 'backend',
        },
    });

    const props = defineProps({
        pageUrl: {
            type: String,
            default: '',
        },
    });

    const closeBackendMenu = () => {
        menuOpen.value = false;
    }

    const openBackendMenu = () => {
        menuOpen.value = true;
    }

</script>

<template>
    <NuxtErrorBoundary>
        <LayoutBackendMenu 
            :menuOpen="menuOpen"
            :pageUrl="props.pageUrl"
            @click="closeBackendMenu"
        />

        <div id="app__container">
            <LayoutBackendHeader 
                @click="openBackendMenu"
            />
            <!-- Main content -->
            <main id="app__main">
                <!-- Toast -->
                <PrimevueToast position="top-right" group="tr" />

                <!-- Page slot -->
                <section id="app__content">
                    <!-- Breadcrumb slot -->
                    <slot name="breadcrumb" />

                    <div v-if="$slots['container-header']" id="app__content__header">
                        <slot name="container-header"/>
                    </div>

                    <slot/>
                </section>
            </main>
        </div>
    </NuxtErrorBoundary>
</template>